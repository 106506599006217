(function(window) {
    /**
     * Shortcut document/element querySelector.
     *
     * @param {string} selector
     * @param {Element?} node
     * @return {Element|null}
     */
    const $ = (selector, node) => node === undefined
        ? document.querySelector(selector)
        : node.querySelector(selector)

    /**
     * Shortcut document/element querySelectorAll.
     *
     * @param {string} selector
     * @param {Element?} node
     * @return {NodeListOf<Element>}
     */
    const $$ = (selector, node) => node === undefined
        ? document.querySelectorAll(selector)
        : node.querySelectorAll(selector)

    // Main application bootstrapper
    const app = window.app = {
        // CSRF token and and value
        csrf: {
            name: null,
            token: null
        },
        // Online study guide
        study: {
            topic: null,
            post: null,
            time: null
        },
        // Helper DOM nodes
        dom: {
            alert: $('#error .alert') && $('#error .alert').cloneNode(true),
            error: $('#error')
        },
        // Translations for minute and second labels
        tr: {
            minute0: null,
            minute1: null,
            minute2: null,
            quiz: null,
            second0: null,
            second1: null,
            second2: null,
        },
        // Runs when document readystate is interactive
        interactive() {
            alerts()
            collapses()
            inputs()
            offcanvas()
            quiz()
            scroll()
            switches()
            carousel()
            type()

            if (this.dom.error) this.dom.error.innerHTML = ''

            if (this.study.time) {
                interval()

                const id = setInterval(() => {
                    interval()

                    if (this.study.time <= 0) clearInterval(id)
                }, 1000)
            }
        },
    }

    // Shortcut for translating a countdown label
    const tn = (t, n) => app.tr[t + (n > 1 ? 2 : n < 1 ? 0 : 1).toString()]

    /**
     * Dismissable alerts polyfill.
     */
    function alerts() {
        $$('[data-bs-dismiss="alert"]').forEach((button) => {
            const parent = button.parentElement

            if (parent.classList.contains('alert-dismissable')) {
                button.addEventListener('click', () => {
                    parent.classList.remove('show')
                    parent.addEventListener('transitionend', () => {
                        parent.remove()
                    })
                })
            }
        })
    }

    /**
     * Carousel polyfill
     */
    function carousel() {
        const ACTIVE_CLASS = 'active'
        const DIRECTION_NEXT = 'carousel-item-next'
        const DIRECTION_PREV = 'carousel-item-prev'
        const ORDER_START = 'carousel-item-start'
        const ORDER_END = 'carousel-item-end'

        $$('.carousel').forEach((widget) => {
            const items = Array.from($$('.carousel-item', widget))
            const buttons = Array.from($$('.carousel-indicators button', widget))

            const active = () =>
                $('.carousel-item.active')

            const index = () =>
                items.indexOf(active())

            const indicator = () =>
                $('.carousel-indicators .active')

            const goto = (number) => {
                const current = index()

                if (number === current) return

                const last = items.length - 1
                const activeSlide = active()
                const activeButton = indicator()
                const toIndex = number < 0 ? last : number > last ? 0 : number
                const toSlide = items[toIndex]
                const toButton = buttons[toIndex]
                const classDirection = number > current ? DIRECTION_NEXT : DIRECTION_PREV
                const classOrder = number > current ? ORDER_START : ORDER_END

                function ended() {
                    activeSlide.classList.remove(ACTIVE_CLASS, ORDER_START, ORDER_END)
                    activeSlide.removeEventListener('transitionend', ended)
                    toSlide.classList.add(ACTIVE_CLASS)
                    toSlide.classList.remove(DIRECTION_NEXT, DIRECTION_PREV, ORDER_START, ORDER_END)
                }

                activeSlide.classList.add(classOrder)
                activeSlide.addEventListener('transitionend', ended)
                activeButton.classList.remove(ACTIVE_CLASS)
                toSlide.classList.add(classDirection)
                toButton.classList.add(ACTIVE_CLASS)

                setTimeout(() => toSlide.classList.add(classOrder), 10)
            }

            $$('[data-bs-slide-to]').forEach((node) => {
                node.addEventListener('click', (event) => {
                    event.preventDefault()
                    goto(parseInt(node.dataset.bsSlideTo, 10))
                })
            })

            $$('[data-bs-slide="prev"]').forEach((node) => {
                node.addEventListener('click', (event) => {
                    event.preventDefault()
                    goto(index() - 1)
                })
            })

            $$('[data-bs-slide="next"]').forEach((node) => {
                node.addEventListener('click', (event) => {
                    event.preventDefault();
                    goto(index() + 1)
                })
            })

            if ($('[data-bs-ride="carousel"]')) {
                let interval
                const runInterval = () => {
                    interval = setInterval(() => goto(index() + 1), 5000)
                }

                widget.addEventListener('pointerover', () => clearInterval(interval))
                widget.addEventListener('pointerout', () => runInterval())

                runInterval()
            }
        })
    }

    /**
     * Hides the study error message if it's shown.
     *
     * @returns {void}
     */
    function clear() {
        if (app.dom.alert) app.dom.alert.classList.remove('show')
    }

    /**
     * Collapse polyfill extended with [data-bs-target-inverse="..."].
     *
     * The target(s) specified in the attribute will receive the inverse
     * show/hide classes similar to accordions collapsing siblings.
     *
     * @returns {void}
     */
    function collapses() {
        const SHOW_CLASS = 'show'
        const COLLAPSED_CLASS = 'collapsed'

        $$('[data-bs-toggle="collapse"]').forEach((widget) => {
            const target = widget.dataset.bsTarget
                ? widget.dataset.bsTarget
                : widget.attributes.href.value;

            if (!target) throw new Error('No target specified for toggle collapse')

            const targeted = target[0] === "#" ? $(target) : $$(target).item(0);

            widget.addEventListener('click', (event) => {
                event.preventDefault()

                if (targeted.classList.contains(SHOW_CLASS)) {
                    targeted.dispatchEvent(new Event('hide.bs.collapse'))
                } else {
                    targeted.dispatchEvent(new Event('show.bs.collapse'))
                }

                widget.classList.toggle(COLLAPSED_CLASS)
                targeted.classList.toggle(SHOW_CLASS)

                setTimeout(() => {
                    if (targeted.classList.contains(SHOW_CLASS)) {
                        targeted.dispatchEvent(new Event('shown.bs.collapse'))
                    } else {
                        targeted.dispatchEvent(new Event('hidden.bs.collapse'))
                    }
                })

                // i.e. [data-bs-target-inverse="..."]
                if (widget.dataset.bsTargetInverse) {
                    const invert = widget.dataset.bsTargetInverse;
                    const inverted = invert[0] === "#" ? [$(invert)] : $$(invert);

                    inverted.forEach((el) => {
                        if (el.classList.contains(SHOW_CLASS)) {
                            el.dispatchEvent(new Event('hide.bs.collapse'))
                        } else {
                            el.dispatchEvent(new Event('show.bs.collapse'))
                        }

                        el.classList.toggle(SHOW_CLASS)

                        setTimeout(() => {
                            if (el.classList.contains(SHOW_CLASS)) {
                                el.dispatchEvent(new Event('shown.bs.collapse'))
                            } else {
                                el.dispatchEvent(new Event('hidden.bs.collapse'))
                            }
                        })
                    })
                }

                if (targeted.dataset.bsParent) {
                    $$(`${targeted.dataset.bsParent} [data-bs-toggle="collapse"]`).forEach((el) => {
                        if (el !== widget) {
                            el.classList.add(COLLAPSED_CLASS)
                        }
                    })

                    $$(`[data-bs-parent="${targeted.dataset.bsParent}"]`).forEach((el) => {
                        if (el !== targeted) {
                            el.classList.remove(SHOW_CLASS)
                        }
                    })
                }
            })
        });
    }

    /**
     * Enables buttons to go to the next topic.
     *
     * @returns {void}
     */
    function complete() {
        if ($('#study-menu a.next')) {
            $('#study-menu a.next').classList.remove('d-none')
            $('#study-menu a.next + span').classList.add('d-none')
        }

        if ($('#study-content')) {
            $('#study-next').classList.remove('d-none')
            $('#study-next-countdown').classList.add('d-none')
        }
    }

    /**
     * Countdown widget update function.
     *
     * @returns {void}
     */
    function countdown() {
        const minutes = Math.floor(app.study.time / 60)
        const minute = tn('minute', minutes)
        const seconds = app.study.time % 60
        const second = tn('second', seconds)

        $$('.countdown-widget').forEach((widget) => {
            const padding = widget.classList.contains('countdown-pad')

            $$('.countdown-digit', widget).forEach((node) => {
                if (node.classList.contains('countdown-minutes')) {
                    node.innerText = minutes
                }

                if (node.classList.contains('countdown-seconds')) {
                    node.innerText = padding ? pad(seconds) : seconds
                }
            })

            $$('.countdown-label', widget).forEach((node) => {
                if (node.classList.contains('countdown-minutes')) {
                    node.innerText = minute
                }

                if (node.classList.contains('countdown-seconds')) {
                    node.innerText = second
                }
            })
        })
    }

    /**
     * Decodes a JSON string into an object or returns an error object.
     *
     * @param {String} string String to decode
     * @returns {{success: Boolean, message: String}}
     */
    function decode(string) {
        try {
            return JSON.parse(string)
        } catch (e) {
            return {
                message: e.message,
                success: false
            }
        }
    }

    /**
     * Shows the study error with the given message.
     *
     * @param {String} message Message to display
     * @returns {void}
     */
    function error(message) {
        if (app.dom.alert && app.dom.error) {
            const alert = app.dom.alert

            $('#error-message', alert).innerText = message

            app.dom.error.innerHTML = ''
            app.dom.error.append(alert)

            alert.classList.add('show')

            // Rebind alert-dismissable
            alerts()
        }
    }

    /**
     * Removes the error class from form inputs when they are blurred.
     *
     * @returns {void}
     */
    function inputs() {
        $$('input.error,select.error').forEach((node) => {
            node.addEventListener('blur',() => {
                node.classList.remove('error')
                let prev = node.previousElementSibling
                if (prev) prev.classList.remove('error')
                if (prev) prev = prev.previousElementSibling
                if (prev) prev.classList.remove('error')
            })
        })
    }

    /**
     * Main function called every second that updates the time remaining and
     * any DOM that needs to update at the same frequency(e.g. countdowns).
     *
     * @returns {void}
     */
    function interval() {
        const time = app.study.time = app.study.time - 1

        countdown()

        if (time <= 0) {
            post('complete')
            complete()
        } else if (time % 5 === 0) {
            post('update', { time })
        }
    }

    /**
     * Offcanvasa polyfill.
     */
    function offcanvas() {
        const backdrop = document.createElement('div')
        const widget = $('#offcanvas')

        backdrop.classList.add('offcanvas-backdrop', 'fade')
        backdrop.addEventListener('click', () => {
            backdrop.classList.remove('show')
            widget.classList.add('hiding')
        })
        backdrop.addEventListener('transitionend', () => {
            if (!backdrop.classList.contains('show')) {
                backdrop.remove()
                widget.classList.remove('show', 'hiding')
            }
        })

        $$('[data-bs-toggle="offcanvas"]').forEach((node) => {
            node.addEventListener('click', (event) => {
                event.preventDefault()
                widget.classList.add('show')
                document.body.append(backdrop)
                setTimeout(() => backdrop.classList.add('show'), 10)
            })
        })

        $$('[data-bs-dismiss="offcanvas"]').forEach((node) => {
            node.addEventListener('click', () => {
                backdrop.classList.remove('show')
                widget.classList.add('hiding')
            })
        })
    }

    /**
     * Pads a number with a leading zero.
     *
     * @param {Number} number Number to pad
     * @returns {String}
     */
    function pad(number) {
        return number < 10 ? '0' + number.toString() : number.toString()
    }

    /**
     * Wrapper for fetch to update progress using the study/post route.
     *
     * @param {String} request Request type to make
     * @param {Object} [payload] Additionaly data to send
     * @returns {void}
     */
    function post(request, payload) {
        const credentials = 'same-origin'
        const body = new FormData()

        body.append(app.csrf.name, app.csrf.token)
        body.append('request', request)
        body.append('topic', app.study.topic)

        Object.keys(payload || {}).forEach((key) => {
            body.append(key, payload[key])
        })

        fetch(app.study.post, { body, credentials, method: 'POST' })
            .then((response) => read(response.body.getReader()))
            .then((response) => decode(response))
            .then((response) => {
                response.success ? clear() : error(response.message)
            })
            .catch((err) => {
                console.error(err)
                error(err.message)
            })
    }

    /**
     * Prevents quizzes from being submitted unless all questions have a selected answer.
     *
     * @returns {void}
     */
    function quiz() {
        const form = $('#quiz-form')

        if (form) {
            form.addEventListener('submit', (event) => {
                const questions = Array.from($$('.question'))

                if (questions.length !== $$('input:checked').length) {
                    event.preventDefault()

                    alert(app.tr.quiz)

                    const unchecked = questions.find((question) => !$('input:checked', question))

                    window.scrollTo(0, unchecked.offsetTop)
                }
            })
        }
    }

    /**
     * Reads a stream and returns its contents as a promise.
     *
     * @param {ReadableStreamReader} reader Stream to read
     * @returns {Promise<String>}
     */
    function read(reader) {
        // Shortcut for converting U8IntArray integers into ASCII
        const char = (int) => String.fromCharCode(int)

        return new Promise((resolve) => {
            let results = ''
            const process = (chunk) => {
                if (chunk.done) {
                    resolve(results)
                } else {
                    results = results + Array.from(chunk.value).map(char).join('')
                    return reader.read().then(process)
                }
            }

            reader.read().then(process)
        })
    }

    /**
     * Handles the scroll to top button logic.
     *
     * @returns {void}
     */
    function scroll() {
        const button = $('#scroll-top')
        const content = $('#account-content') || $('#study-content')
        const offset = $('#content').offsetTop
        let top = 0

        // Handler that shows/hides the scroll to top button
        const handler = () => {
            if (window.scrollY > offset && !button.classList.contains('show')) {
                button.classList.add('show')
            }

            if (window.scrollY <= offset && button.classList.contains('show')) {
                button.classList.remove('show')
            }
        }

        handler()

        button.addEventListener('click', () => {
            window.scrollTo(0, 0)
        })

        window.addEventListener('scroll', handler)

        // Scrolling content when collapsing/expanding mobile menu
        if (content) {
            content.addEventListener('hide.bs.collapse', (event) => {
                if (event.target === content) {
                    top = window.scrollY

                    if (top > offset) {
                        setTimeout(() => {
                            window.scrollTo({ behavior: 'auto', left: 0, top: offset })
                        })
                    }
                }
            })

            content.addEventListener('shown.bs.collapse', (event) => {
                if (event.target === content) {
                    setTimeout(() => {
                        window.scrollTo(0, top)
                    })
                }
            })
        }
    }

    /**
     * Switch that shows and hides DOM based on an input's value.
     *
     * @returns {void}
     */
    function switches() {
        const HIDE_CLASS = 'collapse'

        $$('[data-js-switch]').forEach((widget) => {
            const value = $('[data-js-value]', widget)

            value.addEventListener('change', () => {
                let fallback = true
                const val = value.value

                $$('[data-js-case]', widget).forEach((node) => {
                    if (node.dataset.jsCase === val) {
                        fallback = false

                        node.classList.remove(HIDE_CLASS)
                    } else {
                        node.classList.add(HIDE_CLASS)
                    }
                })

                $$('[data-js-default]', widget).forEach((node) => {
                    if (fallback) {
                        node.classList.remove(HIDE_CLASS)
                    } else {
                        node.classList.add(HIDE_CLASS)
                    }
                })
            })

            value.dispatchEvent(new InputEvent('change'))
        })
    }

    /**
     * Adds a new toggle feature to change an input's type.
     *
     * @returns {void}
     */
    function type() {
        $$('button[data-bs-toggle="type"]').forEach((widget) => {
            /**
             * @typedef {object} Dataset
             * @property {string} bsTarget
             * @property {string} [bsType]
             */
            const target = widget.dataset.bsTarget
            const targeted = target[0] === '#' ? [$(target)] : $$(target)
            const original = targeted.map((el) => ({el, type: el.type}))
            const type = widget.dataset.bsType ? widget.dataset.bsType : 'text'

            widget.addEventListener('click', () => {
                targeted.forEach((el) => {
                    if (el.type === type) {
                        el.type = original.find((item) => item.el === el).type
                    } else {
                        el.type = type
                    }
                })
            })
        })
    }

    // Using readystatechange for hooking into onready and onload
    document.addEventListener('readystatechange', function (event) {
        if (event.target.readyState === 'interactive') app.interactive()
    })
})(window)
